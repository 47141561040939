<template>
  <div class="app">
   
<!-- For Large Screen -->

<v-app-bar color="#3D3D3D"  class="px-2 pt-2" :height="$vuetify.breakpoint.name == 'xs'? '80px': '70px'"  flat>
  <v-layout align-center justify-space-between wrap >
    <v-flex  xs="auto" class="d-flex align-center">
      <v-icon color="white" size="15">mdi-phone</v-icon>
      <span class="headerfont ml-2" style="font-size: 12px; color: white">+91 9497326172</span>
      <v-icon v-if="$vuetify.breakpoint.smAndUp" color="white" size="15" class="ml-2">mdi-email</v-icon>
      <span v-if="$vuetify.breakpoint.smAndUp" class="headerfont ml-2" style="font-size: 12px; color: white">kumarakomventures@gmail.com</span>
    </v-flex>
    <v-flex xs="auto" class="d-flex align-center justify-end">
      <v-icon color="white" size="15" class="ml-2 mr-sm-3">mdi-instagram</v-icon>
      <v-icon color="white" size="15" class="ml-2 mr-sm-3">mdi-facebook</v-icon>
      <v-icon color="white" size="15" class="ml-2 mr-sm-3">mdi-twitter</v-icon>
    </v-flex>
    <v-flex xs12 sm-auto class="d-flex justify-start">
      <v-icon v-if="!$vuetify.breakpoint.smAndUp" color="white" size="15">mdi-email</v-icon>
      <span v-if="!$vuetify.breakpoint.smAndUp" class="headerfont ml-2" style="font-size: 12px; color: white">kumarakomventures@gmail.com</span>
    </v-flex>
   
    <v-flex >
      <div  class="moving-text-wrapper">
        <div v-if="$vuetify.breakpoint.smAndUp" class="moving-text">Rubicon Career Development does not act as a recruiter or offer employment. Instead, we facilitate client development through short-term education and employment related training courses , which would differentiate our clients from the rest of the pack, in the everchanging global labour market.
        </div>
      </div>
    </v-flex>
    <v-flex pt-1>
      <div  class="moving-text-wrapper">
        <div v-if="!$vuetify.breakpoint.smAndUp" class="moving-text">Rubicon Career Development does not act as a recruiter or offer employment. Instead, we facilitate client development through short-term education and employment related training courses , which would differentiate our clients from the rest of the pack, in the everchanging global labour market.
        </div>
      </div>
    </v-flex>

  </v-layout>

  <!-- <v-layout>
    <v-flex>
      <div class="marquee-container">
        <div class="marquee">
          <span class="marquee-text">This is a scrolling text in the app bar using CSS animations.</span>
        </div>
      </div>
    </v-flex>
  </v-layout> -->
  
</v-app-bar>


    <v-layout wrap class="hidden-sm-and-down app px-0">
      <v-flex xs12 sm12 md12>
        <v-app-bar color="white" class="px-0" flat elevation="5">
          <!-- <header> -->
          <v-layout wrap justify-center class="hidden-sm-and-down app px-0">
            <v-flex xs12>
              <!-- <v-img src="../../assets/Images/hd.jpg"> -->
              <v-layout wrap fill-height>
                <v-flex xs12 md10 style="text-align: justify; cursor: pointer" text-center align-self-center>
                  <!-- <v-card color="white" elevation="0"> -->
                  <v-layout wrap>
                    <v-flex py-4 md2>
                      <router-link to="/" style="text-decoration: none">
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-img contain style="cursor: pointer" text src="./../../assets/kumarakom.png"
                              height="60px"></v-img>
                          </v-flex>
                        </v-layout>
                      </router-link>
                    </v-flex>
                    <v-flex md10 pl-lg-16>
                      <v-layout wrap justify-space-between class="headerfont">
                        <!-- <v-flex xs1 pt-1><a class="interSB" style="text-decoration:none; color:white" href="/#about">About</a></v-flex> -->
                        <!-- <v-hover v-slot="{ hover }"> -->

                        <v-flex style="text-align: justify; cursor: pointer" text-center align-self-center>
                          <router-link to="/home" style="text-decoration: none">
                            <v-hover v-slot="{ hover }"><span class="mr-3"
                                :style="hover ? 'color:#1B6DC1' : 'color:black'">
                                Home</span></v-hover>
                          </router-link>
                        </v-flex>


                        <v-flex style="text-align: justify; cursor: pointer" text-center align-self-center>
                          <div class="dropdown-container">
                            <v-menu offset-y @input="toggleMenu('main')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-hover v-slot="{ hover }">
                                  <v-flex
                                    style="display: inline-flex; align-items: center; text-align: justify; cursor: pointer"
                                    text-center align-self-center v-bind="attrs" v-on="on">
                                    <span class="mr-3" :style="hover ? 'color:#1B6DC1' : 'color:black'">
                                      Services
                                      <v-icon
                                        :style="{ color: hover ? '#1B6DC1' : 'black', transform: mainMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)' }">mdi-chevron-down</v-icon>
                                    </span>
                                  </v-flex>
                                </v-hover>
                              </template>
                              <v-list style="font-family:OutfitMedium;">
                                <v-list-item v-for="(service, serviceIndex) in services" :key="serviceIndex">
                                  <template v-if="service.subservices.length > 0">
                                    <v-menu offset-x nudge-right="20" @input="toggleSubMenu(serviceIndex)">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-list-item-content v-on="on" v-bind="attrs">
                                          <v-hover v-slot="{ hover }">
                                            <v-list-item-title :style="hover ? 'color:#1B6DC1' : 'color:black'">
                                              {{ service.name }}
                                              <v-icon
                                                :style="{ color: hover ? '#1B6DC1' : 'black', transform: service.isOpen ? 'rotate(-90deg)' : 'rotate(0deg)' }">mdi-chevron-down</v-icon>
                                            </v-list-item-title>
                                          </v-hover>
                                        </v-list-item-content>
                                      </template>
                                      <v-list style="font-family:OutfitMedium;">
                                        <v-list-item v-for="(subService, subserviceIndex) in service.subservices"
                                          :key="subserviceIndex">
                                          <template
                                            v-if="subService.subsubservices && subService.subsubservices.length > 0">
                                            <v-menu offset-x nudge-right="20"
                                              @input="toggleSubSubMenu(serviceIndex, subserviceIndex)">
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-list-item-content v-on="on" v-bind="attrs">
                                                  <v-hover v-slot="{ hover }">
                                                    <v-list-item-title :style="hover ? 'color:#1B6DC1' : 'color:black'">
                                                      {{ subService.name }}
                                                      <v-icon
                                                        :style="{ color: hover ? '#1B6DC1' : 'black', transform: subService.isOpen ? 'rotate(-90deg)' : 'rotate(0deg)' }">mdi-chevron-down</v-icon>
                                                    </v-list-item-title>
                                                  </v-hover>
                                                </v-list-item-content>
                                              </template>
                                              <v-list style="font-family:OutfitMedium;">
                                                <v-list-item
                                                  v-for="(subSubService, subsubserviceIndex) in subService.subsubservices"
                                                  :key="subsubserviceIndex">
                                                  <router-link :to="subSubService.link" style="text-decoration: none;">
                                                    <v-list-item-content>
                                                      <v-list-item-title>{{ subSubService.name }}</v-list-item-title>
                                                    </v-list-item-content>
                                                  </router-link>
                                                </v-list-item>
                                              </v-list>
                                            </v-menu>
                                          </template>
                                          <template v-else-if="subService.link">
                                            <router-link :to="subService.link" style="text-decoration: none;">
                                              <v-list-item-content>
                                                <v-list-item-title>{{ subService.name }}</v-list-item-title>
                                              </v-list-item-content>
                                            </router-link>
                                          </template>
                                          <template v-else>
                                            <v-list-item-content>
                                              <v-list-item-title>{{ subService.name }}</v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                  </template>
                                  <template v-else>
                                    <v-list-item-content>
                                      <v-list-item-title>{{ service.name }}</v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </v-flex>




                        <v-flex style="text-align: justify; cursor: pointer" text-center align-self-center v-scroll-to="{
                          el: '#our-aboutus',
                          duration: 500,
                          easing: 'ease-in',
                        }">
                          <router-link to="/allcourses" style="text-decoration: none"><v-hover v-slot="{ hover }">
                              <span class="mr-3"
                                :style="hover ? 'color:#1B6DC1' : 'color:black'">Courses</span></v-hover></router-link>
                        </v-flex>

                        <v-flex style="text-align: justify; cursor: pointer" text-center align-self-center v-scroll-to="{
                          el: '#our-aboutus',
                          duration: 500,
                          easing: 'ease-in',
                        }">
                          <router-link to="/home" style="text-decoration: none"><v-hover v-slot="{ hover }">
                              <span class="mr-3" :style="hover ? 'color:#1B6DC1' : 'color:black'">About
                                Us</span></v-hover></router-link>
                        </v-flex>

                        <!-- <v-flex
                          style="text-align: justify; cursor: pointer"
                          text-center
                          align-self-center
                          v-if="shouldDisplayCoursesLink()"
                        >
                          <router-link
                            to="/home"
                            style="text-decoration: none"
                            ><v-hover v-slot="{ hover }"
                              ><span
                                :style="hover ? 'color:#D30D0D' : 'color:black'"
                                >Courses</span
                              ></v-hover
                            ></router-link
                          >
                        </v-flex> -->

                        <v-flex style="text-align: justify; cursor: pointer" text-center align-self-center>
                          <router-link to="/contactUs" style="text-decoration: none">
                            <v-hover v-slot="{ hover }">
                              <span class="mr-3" :style="hover ? 'color:#1B6DC1' : 'color:#2E2E2E'">Contact Us
                              </span></v-hover></router-link>
                        </v-flex>
                        <v-flex py-6>
                          <v-divider vertical style="min-height: 50px; max-height: 50px"></v-divider>
                        </v-flex>

                        <v-flex style="text-align: justify; cursor: pointer" text-center align-self-center v-scroll-to="{
                          el: '#our-aboutus',
                          duration: 500,
                          easing: 'ease-in',
                        }">
                          <v-icon color="black" class="mb-1">mdi-account</v-icon>
                          <!-- Added user icon -->

                          <router-link to="/home" style="text-decoration: none">
                            <v-hover v-slot="{ hover }">
                              <span class="ml-2" :style="hover ? 'color:#1B6DC1' : 'color:black'">Login</span>
                            </v-hover>
                          </router-link>

                          <v-btn class="ml-3  " color="primary" small>
                            <router-link to="/home" style="text-decoration: none">
                              <span   style="color: white; ">Sign Up</span>
                            </router-link>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!-- </v-card> -->
                </v-flex>
              </v-layout>
              <!-- </v-img> -->
            </v-flex>
          </v-layout>
          <!-- </header> -->
        </v-app-bar>
      </v-flex>
    </v-layout>

    <!-- MOBILE SCREEN -->

    <v-layout wrap class="hidden-md-and-up">
      <v-flex xs12>
        <v-app-bar color="white" class="px-0">
          <v-layout class="hidden-md-and-up">
            <v-flex xs12>
              <!-- <v-img src="../../assets/Images/hd.jpg"> -->
              <v-layout wrap>
                <v-flex xs11 align-self-start>
                  <router-link to="/" style="text-decoration: none">
                    <v-layout wrap justify-start>
                      <v-flex xs3 sm1 pt-1>
                        <!-- <v-img contain text src="../assets/Images/kumarakom.png" height="40px"></v-img> -->

                        <v-img contain style="cursor: pointer" text src="./../../assets/kumarakom.png"
                          height="50px"></v-img>
                      </v-flex>
                    </v-layout>
                  </router-link>
                </v-flex>
                <v-flex xs1 pt-5>
                  <v-app-bar-nav-icon @click="drawer = !drawer">
                    <v-icon color="White">mdi-menu</v-icon>
                  </v-app-bar-nav-icon>
                </v-flex>
              </v-layout>
              <!-- </v-img> -->
            </v-flex>
          </v-layout>
        </v-app-bar>
      </v-flex>
      <v-navigation-drawer width="300px" right v-model="drawer" app fixed temporary>
        <v-layout wrap justify-center>
          <v-flex>

            <div class="sidebar">
              <ul>
                <li v-for="item in items" :key="item.name">
                  <div @click="toggle(item)" v-if="!item.route">
                    <i :class="item.icon"></i> {{ item.name }}
                    <v-icon v-if="item.children && item.children.length > 0">
                      {{ item.expanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
                    </v-icon>
                  </div>
                  <router-link style="text-decoration:none;color:black" v-else :to="item.route"
                    @click.native="drawer = false">
                    <div>
                      <i :class="item.icon"></i> {{ item.name }}
                      <v-icon v-if="item.children && item.children.length > 0">
                        {{ item.expanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
                      </v-icon>
                    </div>
                  </router-link>
                  <ul v-if="item.children && item.expanded">
                    <li v-for="child in item.children" :key="child.name">
                      <div @click="toggle(child)" v-if="!child.route">
                        <i :class="child.icon"></i> {{ child.name }}
                        <v-icon v-if="child.children && child.children.length > 0">
                          {{ child.expanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
                        </v-icon>
                      </div>
                      <router-link style="text-decoration:none;color:black" v-else :to="child.route"
                        @click.native="drawer = false">
                        <div>
                          <i :class="child.icon"></i> {{ child.name }}
                          <v-icon v-if="child.children && child.children.length > 0">
                            {{ child.expanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
                          </v-icon>
                        </div>
                      </router-link>
                      <ul v-if="child.children && child.expanded">
                        <li v-for="grandChild in child.children" :key="grandChild.name">
                          <div @click="toggle(grandChild)" v-if="!grandChild.route">
                            <i :class="grandChild.icon"></i> {{ grandChild.name }}
                            <v-icon v-if="grandChild.children && grandChild.children.length > 0">
                              {{ grandChild.expanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
                            </v-icon>
                          </div>
                          <router-link style="text-decoration:none;color:black" v-else :to="grandChild.route"
                            @click.native="drawer = false">
                            <div>
                              <i :class="grandChild.icon"></i> {{ grandChild.name }}
                              <v-icon v-if="grandChild.children && grandChild.children.length > 0">
                                {{ grandChild.expanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
                              </v-icon>
                            </div>
                          </router-link>
                          <ul v-if="grandChild.children && grandChild.expanded">
                            <li v-for="greatGrandChild in grandChild.children" :key="greatGrandChild.name">
                              <div @click="toggle(greatGrandChild)" v-if="!greatGrandChild.route">
                                <i :class="greatGrandChild.icon"></i> {{ greatGrandChild.name }}
                              </div>
                              <router-link style="text-decoration:none;color:black" v-else :to="greatGrandChild.route"
                                @click.native="drawer = false">
                                <div>
                                  <i :class="greatGrandChild.icon"></i> {{ greatGrandChild.name }}
                                </div>
                              </router-link>
                              <ul v-if="greatGrandChild.children && greatGrandChild.expanded">
                                <li v-for="greatGreatGrandChild in greatGrandChild.children"
                                  :key="greatGreatGrandChild.name">
                                  <div>
                                    {{ greatGreatGrandChild.name }}
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <!-- <v-list dense nav>
  
                <v-list-item-group active-class="blue--text text--accent-4">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-img height="20px" contain></v-img>
                    </v-list-item-icon>
                    <v-list-item-title><router-link to="/" style="text-decoration: none"><span class="headerfont"
                          style="color: black" @click="drawer = false">Home</span></router-link></v-list-item-title>
                  </v-list-item>
  
               
                  
  
                  <v-list-item>
                    <v-list-item-icon>
                      <v-img height="20px" contain></v-img>
                    </v-list-item-icon>
                    <v-list-item-title><router-link to="" style="text-decoration: none"><span class="headerfont"
                          style="color: black" @click="drawer = false" v-scroll-to="{
                            el: '#our-aboutus',
                            duration: 500,
                            easing: 'ease-in',
                          }">About Us</span></router-link></v-list-item-title>
                  </v-list-item>
  
  
  
                  <v-list-item>
                    <v-list-item-icon>
                      <v-img height="20px" contain></v-img>
                    </v-list-item-icon>
                    <v-list-item-title>
                      <router-link to="" style="text-decoration: none">
                        <span style="color: black" class="headerfont" @click="drawer = false">Courses</span>
                      </router-link>
                    </v-list-item-title>
                  </v-list-item>
  
                  <v-list-item>
                    <v-list-item-icon>
                      <v-img height="20px" contain></v-img>
                    </v-list-item-icon>
                    <v-list-item-title>
                      <router-link to="/contactUs" style="text-decoration: none">
                        <span style="color: black" class="headerfont" @click="drawer = false">Contact Us</span>
                      </router-link>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
  
                    <v-list-item-title class="ml-12">
                      <router-link to="/" style="text-decoration: none">
                        <v-icon color="black" class=" mb-2 ml-0">mdi-account</v-icon>
                        <span style="color: black" class="headerfont ml-2" @click="drawer = false">Login</span>
                      </router-link>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
  
                    <v-list-item-title class="ml-12">
                      <router-link to="/" style="text-decoration: none">
                        <v-icon color="black" class=" mb-2 ml-0">mdi-account</v-icon>
                        <span style="color: black" class="headerfont ml-2" @click="drawer = false">Sign Up</span>
                      </router-link>
                    </v-list-item-title>
                  </v-list-item>
  
                </v-list-item-group>
              </v-list> -->

            <!-- <v-list style="font-family: JostSemiBold;text-decoration:none">
              <v-list-group v-for="item in items" :key="item.title" :prepend-icon="item.action">
                <template v-slot:activator>
                  <v-list-item-content >
                    <v-list-item-title><router-link style="text-decoration:none;color:black" :to="item.route">{{ item.title }}</router-link>
                      <v-icon v-if="item.items" class="mr-2">{{
                        item.active ? "mdi-menu-down" : "mdi-menu-right"
                      }}</v-icon>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-group :value="true" no-action sub-group v-for="child in item.items" :key="child.title">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title><router-link :to="child.route">{{ child.title }}</router-link></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-group :value="true" no-action sub-group v-for="subChild in child.items" :key="subChild.title">
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title><router-link :to="subChild.route">{{
                          subChild.title
                        }}</router-link></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-group class="ml-10"   v-for="subChild1 in subChild.items"
                      :key="subChild1.title">
                      <template v-slot:activator>
                        <v-list-item-content >
                          <v-list-item-title><router-link :to="subChild1.route">{{
                            subChild1.title
                          }}</router-link></v-list-item-title>
                        </v-list-item-content>
                      </template>
                      
                    </v-list-group>
                  </v-list-group>
                </v-list-group>
              </v-list-group>
            </v-list> -->
          </v-flex>
        </v-layout>
      </v-navigation-drawer>
    </v-layout>

    <router-view style="min-height: 90vh" :key="$route.fullPath"></router-view>

    <!-- </v-app-bar> -->
  </div>
</template>
<script>
export default {
  name: 'Sidebar',
  data() {

    return {
      mainMenuOpen: false,
      drawer: false,
      services: [
        {
          name: 'Learning',
          isOpen: false,
          subservices: [
            {
              name: 'Languages',
              isOpen: false,
              subsubservices: [
                { name: 'Rubicon Carmel Course', link: '/carmelcourse' },

              ]
            },
            {
              name: 'Computer Courses',
              isOpen: false,
              subsubservices: [
                { name: 'Rubicon Zen Course', link: '/zenintro' },

              ]
            },
            { name: 'Accounting Softwares' },
            { name: 'Medical Courses' },
          ]
        },
        {
          name: 'Training and Certification',
          isOpen: false,
          subservices: [
            { name: 'Health & Safety', link: '/onlinecourse' },
          ]
        },
      ],

      items: [
        {
          name: 'Home',
          icon: 'fas fa-home',
          expanded: false,
          route: '/home',
          children: []
        },
        {
          name: 'Services',
          icon: 'fas fa-concierge-bell',
          expanded: false,
          children: [
            {
              name: 'Learning',
              icon: 'fas fa-book',
              expanded: false,
              children: [
                {
                  name: 'Languages',
                  icon: 'fas fa-language',
                  expanded: false,
                  children: [
                    {
                      name: 'Rubycon Carmel Course',
                      route: '/carmelcourse',
                      expanded: false,

                    },]
                },
                {
                  name: 'Computer Courses',
                  icon: 'fas fa-desktop',
                  expanded: false,
                  children: [
                    {
                      name: 'Rubycon Zen Course',
                      route: '/zenintro',
                      expanded: false,
                      children: []
                    }
                  ]
                },
                {
                  name: 'Accounting  Courses',
                  icon: 'fas fa-stethoscope',
                  expanded: false,
                  children: [

                  ]
                },
                {
                  name: 'Medical Courses',
                  icon: 'fas fa-calculator',
                  expanded: false,
                  children: [

                  ]
                }
              ]
            },
            {
              name: 'Training',
              icon: 'fas fa-chalkboard',
              expanded: false,
              children: [{
                name: 'Health $ Safety',
                icon: 'fas fa-heartbeat',
                route: '/onlinecourse',
                expanded: false,
              }]
            },

          ]
        },
        {
          name: 'About Us',
          icon: 'fas fa-info-circle',
          expanded: false,
          route: '/home',
          children: []
        },
        {
          name: 'Courses',
          icon: 'fas fa-graduation-cap',
          expanded: false,
          route: '/home',
          children: []
        },
        {
          name: 'Contact Us',
          icon: 'fas fa-envelope',
          expanded: false,
          route: '/contactUs',
          children: []
        },
        {
          name: 'Login',
          icon: 'fas fa-sign-in-alt',
          expanded: false,
          route: '/home',
          children: []
        },
        {
          name: 'Sign Up',
          icon: 'fas fa-user-plus',
          expanded: false,
          route: '/home',
          children: []
        }
      ]

    };
  },
  methods: {

    toggle(item) {
      item.expanded = !item.expanded;
    },

    toggleMenu(menu) {
      if (menu === 'main') {
        this.mainMenuOpen = !this.mainMenuOpen;
      }
    },
    toggleSubMenu(serviceIndex) {
      this.services[serviceIndex].isOpen = !this.services[serviceIndex].isOpen;
    },
    toggleSubSubMenu(serviceIndex, subserviceIndex) {
      this.services[serviceIndex].subservices[subserviceIndex].isOpen = !this.services[serviceIndex].subservices[subserviceIndex].isOpen;
    }
  }

};
</script>

<style>
.v-list-group__header__append-icon {
  display: none;
}

.selected-item {
  color: #1b6dc1 !important;
}


.dropdown-container {
  position: relative;
}

.router-link {
  text-decoration: none;
}

.navbar {
  overflow: hidden;
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
}

.fixItem {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  /* overflow: hidden;
          position: relative;
          height: 100%; */
}

.fnt1 {
  font-size: 13px;
  font-weight: bold;
}

.app-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  /* adjust to desired height */
  background-color: #fff;
  /* adjust to desired background color */
  border-bottom: 1px solid #ccc;
  /* adjust to desired border style */
  z-index: 999;
  /* adjust to desired z-index */
}

.app-content {
  margin-top: 64px;
  /* adjust to match app bar height */
}

.gradientorange {
  /* background-image:inear-gradient(255.36deg, #7BC142 48.09%, #E95D49 127.63%) ; */
  background-image: linear-gradient(255.36deg, #7bc142 48.09%, #e95d49 127.63%);
}

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  /* adjust height as needed */
  background-color: #fff;
  /* adjust background color as needed */
  border-bottom: 1px solid #ccc;
  /* add a border if needed */
  z-index: 999;
  /* adjust z-index as needed */
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.headerfont {
  font-family: OutfitMedium;
}


.sidebar {
  font-family: outfitMedium;
  background-color: #f8f9fa;
  padding: 10px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul ul {
  margin-left: 20px;
}

.sidebar div {
  cursor: pointer;
  padding: 15px;
}

.sidebar div:hover {
  background-color: #e9ecef;
}

.sidebar ul li i {
  margin-right: 8px;
  /* Adjust the value for icon spacing */
}

.moving-text-wrapper {
  overflow: hidden;
  white-space: nowrap;
}

.moving-text {
  display: inline-block;
  color: #fff;
  font-family: outfitMedium;
  font-size: 13px;

  animation: moveText 60s linear infinite;
}

@keyframes moveText {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

</style>
